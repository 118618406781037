/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
export const markAsRemoved = (payments) => {
  payments.forEach((payment) => {
    if (!payment || !payment.id) {
      return
    }

    const paymentId = payment.vendor_id ? `invoice-${payment.id}` : `expense-${payment.id}`
    const paymentOption = document.getElementById(paymentId)
    const paymentOptionParent = paymentOption.parentNode
    const removedWrapper = document.createElement('div')
    removedWrapper.classList.add('removed-tag-wrapper')
    const removedTag = document.createElement('div')
    removedTag.classList.add('tag', 'tag-rejected')
    removedTag.textContent = 'Removed'
    removedWrapper.appendChild(removedTag)
    paymentOptionParent.removeChild(paymentOption)
    paymentOptionParent.appendChild(removedWrapper)

    const paymentFormId = `edit_${payment.vendor_id ? `invoice_${payment.id}` : `expense_report_${payment.id}`}`
    const paymentForm = document.getElementById(paymentFormId)
    Array.from(paymentForm.elements).forEach((formElement) => { formElement.disabled = true })
  })
}
