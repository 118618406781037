/* eslint-disable import/prefer-default-export */
import { numberToCurrency, currencyToNumber } from '../../utils/currency'

export const adjustPaymentRollupTotals = (payments = []) => {
  payments.forEach((payment) => {
    if (!payment || !payment.id) {
      return
    }

    const paymentRollupId = payment.vendor_id ? `invoice-${payment.vendor_id}-drawer-trigger` : `expense_report-${payment.user_id}-drawer-trigger`
    const paymentRollupWrapper = document.getElementById(paymentRollupId)
    const totalDueWrapper = paymentRollupWrapper.getElementsByClassName('payment-amount-due-rollup')[0]
    const totalDue = currencyToNumber(totalDueWrapper.innerText)

    totalDueWrapper.innerText = numberToCurrency(
      totalDue - (payment.total_amount_paid || payment.amount_due),
    )
  })
}
