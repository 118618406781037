import MicroModal from 'micromodal'

let globalStatus = null

const statusConfirmed = () => {
  const statusInput = document.getElementById('invoice_status')
  if (statusInput) {
    statusInput.value = globalStatus
  }
  if (globalStatus === 'rejected') {
    const assignedToInput = document.getElementById('invoice_assigned_to')
    if (assignedToInput) {
      assignedToInput.value = 'nil'
    }
  }
  MicroModal.close('site-modal')
  const { invoiceForm } = document
  if (invoiceForm) {
    invoiceForm.submit()
  }
}

export const invoiceStatusToggle = (e, status, message) => {
  e.preventDefault()
  globalStatus = status
  const siteModalContent = document.getElementById('site-modal-content')
  const siteModalConfirmation = document.getElementById('site-modal-confirm')
  if (siteModalContent && siteModalConfirmation) {
    siteModalContent.innerHTML = message
    siteModalConfirmation.addEventListener('click', statusConfirmed)
    MicroModal.show('site-modal')
  }
}

export const destroyInvoiceStatusToggle = () => {
  globalStatus = null
  const siteModalConfirmation = document.getElementById('site-modal-confirm')
  if (siteModalConfirmation) {
    siteModalConfirmation.removeEventListener('click', statusConfirmed)
  }
  // TODO: How do we destroy microModal??
}
