/* eslint-disable import/no-unresolved */
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'stylesheets/application'
import { navInit, navDestroy } from '../src/nav'
import { profileNavInit, profileNavDestroy } from '../src/profileNav'
import { filterNavInit, filterNavDestroy } from '../src/filterNav'
import { alertsInit, alertsDestroy } from '../src/alerts'
import { formActionsInit, formActionsDestroy } from '../src/formActions'
import { inputMasksInit, inputMasksDestroy } from '../src/inputMasks'
import { dateTimePickerInit, dateTimePickerDestroy } from '../src/datetimepicker'
import { onInvoicePaymentPreferenceChange } from '../src/invoicePaymentMethodSelection'
import { invoiceAttachmentsInit, invoiceAttachmentsDestroy } from '../src/invoiceAttachments'
import { vendorPaymentOptionsInit, vendorPaymentOptionsDestroy } from '../src/vendorPaymentOptions'
import { userPaymentOptionsInit, userPaymentOptionsDestroy } from '../src/userPaymentOptions'
import { cpcToggleInit, cpcToggleDestroy } from '../src/cpcToggle'
import toggleFilters from '../src/invoiceFilter'
import 'cocoon-js-vanilla'
import { toggleDefaultEmail, toggleDefaultEmailDestroy } from '../src/defaultEmail'
import { invoiceStatusToggle, destroyInvoiceStatusToggle } from '../src/invoiceStatusToggle'
import {
  togglePaymentHeadingOpenState,
  togglePaymentSelections,
} from '../src/payment_queue/paymentSelection'
import { confirmPaymentSelections, destroyConfirmPayment } from '../src/payment_queue/confirmPayments'
import {
  showPaymentPreferenceErrorsMessage,
  destroyPaymentSelectionModal,
} from '../src/payment_queue/createPayments'
import { confirmRemovalFromQueue, destroyRemoveFromQueue } from '../src/payment_queue/confirmRemoval'
import { toggleAccNumInit, toggleAccNumDestroy } from '../src/vendorShowSlider'
import {
  expenseAttachmentChanged, toggleExpenseRow, initializeExpenseButton, destroyExpenseButton,
} from '../src/expenseAttachments'
import rejectCheck from '../src/expenseActions'
import rejectUser from '../src/userActions'
import { paymentMethodsInit, paymentMethodsDestroy } from '../src/paymentMethods'

// Make images available through pack tag.
require.context('../images', true)

// Start Rails services
Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Global and window functions work across ajax loads.
// Be sure to destroy and JS listeners created on global or window objects.
global.Rails = Rails
global.showPaymentPreferenceErrorsMessage = showPaymentPreferenceErrorsMessage
global.confirmPaymentSelections = confirmPaymentSelections
global.togglePaymentSelections = togglePaymentSelections
global.confirmRemovalFromQueue = confirmRemovalFromQueue
global.onInvoicePaymentPreferenceChange = onInvoicePaymentPreferenceChange

// Note: Don't keep sensitive info here as window is accessible from console.
// Maybe these should be protected somehow..
window.expenseAttachmentChanged = expenseAttachmentChanged
window.toggleDefaultEmail = toggleDefaultEmail
window.toggleFilters = toggleFilters
window.invoiceStatusToggle = invoiceStatusToggle
window.toggleExpenseRow = toggleExpenseRow
window.rejectCheck = rejectCheck
window.togglePaymentHeadingOpenState = togglePaymentHeadingOpenState
window.rejectUser = rejectUser

// Init any javascript components
const onTurboLinksLoad = () => {
  navInit()
  profileNavInit()
  filterNavInit()
  alertsInit()
  formActionsInit()
  inputMasksInit()
  dateTimePickerInit()
  invoiceAttachmentsInit()
  vendorPaymentOptionsInit()
  userPaymentOptionsInit()
  cpcToggleInit()
  toggleAccNumInit()
  initializeExpenseButton()
  paymentMethodsInit()
}

// Teardown and javascript components
const onBeforeCache = () => {
  navDestroy()
  profileNavDestroy()
  filterNavDestroy()
  alertsDestroy()
  formActionsDestroy()
  inputMasksDestroy()
  dateTimePickerDestroy()
  invoiceAttachmentsDestroy()
  vendorPaymentOptionsDestroy()
  userPaymentOptionsDestroy()
  cpcToggleDestroy()
  toggleDefaultEmailDestroy()
  destroyInvoiceStatusToggle()
  toggleAccNumDestroy()
  destroyExpenseButton()
  paymentMethodsDestroy()
  destroyPaymentSelectionModal()
  destroyConfirmPayment()
  destroyRemoveFromQueue()
}

const onTurbolinksRender = () => {
}

// Turbolinks hooks:
// Used for mounting and unmounting components and
// Javascript behaviour that has event listeners.
// -------------------------------------------------------
window.addEventListener('turbolinks:load', onTurboLinksLoad)
window.addEventListener('turbolinks:before-cache', onBeforeCache)
window.addEventListener('turbolinks:render', onTurbolinksRender)
