import IMask from 'imask'

const masks = []

export const inputMasksInit = () => {
  // Mask Phone Inputs
  document.querySelectorAll('.mask-phone-input').forEach((phoneInput) => {
    const mask = IMask(
      phoneInput,
      {
        mask: '+{1}(000) 000-0000',
      },
    )

    masks.push(mask)
  })

  // Mask Currency Inputs
  document.querySelectorAll('.mask-currency-input').forEach((currencyInput) => {
    const mask = IMask(
      currencyInput,
      {
        mask: '$num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: ',',
            padFractionalZeros: true,
            radix: '.',
            scale: 2,
          },
        },
      },
    )

    masks.push(mask)
  })

  // Mask email inputs
  document.querySelectorAll('.mask-email-input').forEach((emailInput) => {
    const mask = IMask(
      emailInput,
      {
        mask: /^\S*@?\S*$/,
      },
    )

    masks.push(mask)
  })
}

export const inputMasksDestroy = () => {
  masks.forEach((mask) => {
    mask.destroy()
  })
}
