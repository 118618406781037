/* eslint-disable no-param-reassign */

export const togglePaymentSelections = (e) => {
  const paymentCheckBoxes = [...e.target.closest('.payments-sub-table').querySelectorAll('.payment-item-option')]

  for (let i = 0; i < paymentCheckBoxes.length; i += 1) {
    paymentCheckBoxes[i].checked = e.target.checked
  }
}

export const togglePaymentHeadingOpenState = (e) => {
  const paymentRows = [...e.target.closest('tbody').querySelectorAll('.payment-table-body')]

  paymentRows.forEach((row) => {
    if (row.style.display === 'none') {
      row.style.display = 'block'
    } else {
      row.style.display = 'none'
    }
  })
}
