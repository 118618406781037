/* eslint-disable import/no-cycle */
import MicroModal from 'micromodal'
import { submitPaymentSelections } from './createPayments'
import { selectedInvoices, selectedExpenses } from './selectedItems'
import { destroyRemoveFromQueue } from './confirmRemoval'

export const destroyConfirmPayment = () => {
  const siteModalConfirmation = document.getElementById('site-modal-confirm')

  if (siteModalConfirmation) {
    siteModalConfirmation.removeEventListener('click', submitPaymentSelections)
  }
}

export const confirmPaymentSelections = () => {
  destroyRemoveFromQueue()
  destroyConfirmPayment()
  const siteModalContent = document.getElementById('site-modal-content')
  const siteModalConfirmation = document.getElementById('site-modal-confirm')
  const siteModalCancel = document.getElementById('site-modal-cancel')
  const invoicesLength = selectedInvoices().length
  const expensesLength = selectedExpenses().length
  siteModalConfirmation.addEventListener('click', submitPaymentSelections)
  siteModalConfirmation.innerText = 'Confirm Payment'
  siteModalCancel.innerText = 'Cancel'
  siteModalConfirmation.classList.remove('hidden')

  if (siteModalContent && siteModalConfirmation) {
    if (invoicesLength > 0 || expensesLength > 0) {
      siteModalContent.innerHTML = `
        <p>
          <strong>${invoicesLength}</strong> Invoices and
          <strong>${expensesLength}</strong> Expense Reports will be paid.
        </p>
      `
    } else {
      siteModalContent.innerHTML = `
        <p>
          No items have been selected for payment.
        </p>
      `
      siteModalConfirmation.classList.add('hidden')
    }
    MicroModal.show('site-modal')
  }
}
