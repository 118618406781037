import axios from 'axios'

const udpateInvoicePaymentMethodOptions = (data) => {
  const paymentMethodSelect = document.getElementById('invoice_payment_method_id')
  paymentMethodSelect.innerHTML = '<option value="" label=" "></option>'
  Array.from(data).forEach((option) => {
    paymentMethodSelect.innerHTML += `
      <option value='${option.id}'>${option.name}</option>
    `
  })
}

/* eslint-disable import/prefer-default-export */
export const onInvoicePaymentPreferenceChange = (e) => {
  const tokenElement = document.querySelector('meta[name=csrf-token]')
  const csrfToken = tokenElement ? tokenElement.content : ''

  axios.get('/invoices/toggle_payment_method_options.json', {
    params: {
      payment_preference: e.target.value,
    },
  }, {
    headers: {
      'X-CSRF-Token': csrfToken,
    },
  })
    .then((response) => {
      if (response.data) {
        udpateInvoicePaymentMethodOptions(response.data)
      }
    })
    .catch(() => {
    })
}
