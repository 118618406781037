import MicroModal from 'micromodal'

const rejectCheck = (reportNotes) => {
  const notes = document.getElementById('expense_report_notes')
  if (notes.value.normalize() === reportNotes.normalize()) {
    const siteModalContent = document.getElementById('site-modal-content')
    const siteModalConfirmation = document.getElementById('site-modal-confirm')
    if (siteModalContent && siteModalConfirmation) {
      siteModalContent.innerHTML = 'Please update the \'Notes\' section with reason for rejection before rejecting the report.'
      MicroModal.show('site-modal')
      document.getElementById('site-modal-confirm').classList.add('hidden')
    }
    return false
  }

  return true
}

export default rejectCheck
