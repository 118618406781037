const onChanged = (e) => {
  const invoiceOutput = document.getElementById('added-temp-invoice-files')

  if (invoiceOutput && e.target.files) {
    invoiceOutput.innerHTML = `${e.target.files.length} Files Added`
  }
}

export const invoiceAttachmentsInit = () => {
  const invoiceInput = document.getElementById('invoice_attached_invoices')

  if (invoiceInput) {
    invoiceInput.addEventListener('change', onChanged)
  }
}

export const invoiceAttachmentsDestroy = () => {
  const invoiceInput = document.getElementById('invoice_attached_invoices')

  if (invoiceInput) {
    invoiceInput.removeEventListener('change', onChanged)
  }
}
