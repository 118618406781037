export const selectedInvoices = () => {
  const paymentOptionItems = document.getElementsByClassName('payment-item-option')
  const selected = []

  for (let i = 0; i < paymentOptionItems.length; i += 1) {
    if (paymentOptionItems[i].checked) {
      const nameParts = paymentOptionItems[i].name.split('-')

      if (nameParts[0] === 'invoice') {
        selected.push(nameParts[1])
      }
    }
  }

  return selected
}

export const selectedExpenses = () => {
  const paymentOptionItems = document.getElementsByClassName('payment-item-option')
  const selected = []

  for (let i = 0; i < paymentOptionItems.length; i += 1) {
    if (paymentOptionItems[i].checked) {
      const nameParts = paymentOptionItems[i].name.split('-')

      if (nameParts[0] === 'expense') {
        selected.push(nameParts[1])
      }
    }
  }

  return selected
}
