/* eslint-disable no-param-reassign */
import axios from 'axios'
import MicroModal from 'micromodal'
import { selectedInvoices, selectedExpenses } from './selectedItems'
import { markAsPaid } from './markAsPaid'
import { adjustPaymentRollupTotals } from './adjustTotals'

const digestPaymentResponse = (response) => {
  if (!response.data || !response.data.payments || response.data.errors.length > 0) {
    return
  }

  const { payments } = response.data

  markAsPaid(payments)
  adjustPaymentRollupTotals(payments)
}

export const submitPaymentSelections = () => {
  const siteModalContent = document.getElementById('site-modal-content')
  const tokenElement = document.querySelector('meta[name=csrf-token]')
  const csrfToken = tokenElement ? tokenElement.content : ''

  axios.post('/payments.json', {
    payment: {
      invoice_ids: JSON.stringify(selectedInvoices()),
      expense_report_ids: JSON.stringify(selectedExpenses()),
    },
  }, {
    headers: {
      'X-CSRF-Token': csrfToken,
    },
  })
    .then((response) => {
      MicroModal.close('site-modal')
      digestPaymentResponse(response)

      if (response.data.errors.length > 0 && siteModalContent) {
        siteModalContent.innerHTML = response.data.errors.map((error) => error.message).join(',')
        MicroModal.show('site-modal')
        document.getElementById('site-modal-confirm').classList.add('hidden')
      }
    })
    .catch(() => {
      if (siteModalContent) {
        siteModalContent.innerHTML = 'There were issues creating the payment.'
        MicroModal.show('site-modal')
        document.getElementById('site-modal-confirm').classList.add('hidden')
      }
    })
}

export const showPaymentPreferenceErrorsMessage = () => {
  const siteModalContent = document.getElementById('site-modal-content')

  if (siteModalContent) {
    siteModalContent.innerHTML = 'There were issues saving the payment preference.'
    MicroModal.show('site-modal')
    document.getElementById('site-modal-confirm').classList.add('hidden')
  }
}

export const destroyPaymentSelectionModal = () => {
  const siteModalConfirmation = document.getElementById('site-modal-confirm')

  if (siteModalConfirmation) {
    siteModalConfirmation.removeEventListener('click', submitPaymentSelections)
  }
}
