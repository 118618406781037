const toggleChildItems = (e) => {
  e.preventDefault()

  if (e.target.nodeName === 'A') {
    e.target.parentElement.classList.toggle('active')
  } else {
    e.target.parentElement.parentElement.classList.toggle('active')
  }
}

const toggleOpenState = () => {
  const topLevelNav = document.getElementById('top-level')
  topLevelNav.classList.toggle('opened')
}

export const navInit = () => {
  const navItems = document.querySelectorAll('div#sidebar-nav a.nav-item-toggle')
  const navToggle = document.getElementById('main-nav-toggle')

  navItems.forEach((item) => {
    item.addEventListener('click', toggleChildItems)
  })

  if (navToggle) {
    navToggle.addEventListener('click', toggleOpenState)
  }
}

export const navDestroy = () => {
  const navItems = document.querySelectorAll('div#sidebar-nav a.nav-item-toggle')
  const navToggle = document.getElementById('main-nav-toggle')

  navItems.forEach((item) => {
    item.removeEventListener('click', toggleChildItems)
  })

  if (navToggle) {
    navToggle.removeEventListener('click', toggleOpenState)
  }
}
