import { inputMasksInit, inputMasksDestroy } from './inputMasks'
import { dateTimePickerInit } from './datetimepicker'
import { cpcToggleInit, cpcToggleDestroy } from './cpcToggle'

const addNewExpenseRow = () => {
  // Wait for cocoon to insert new row.
  // Note: Could lead to race condition if cocoon takes longer than
  // 1.2 seconds to render a new row.
  setTimeout(() => {
    inputMasksDestroy()
    inputMasksInit()
    dateTimePickerInit()
    cpcToggleDestroy()
    cpcToggleInit()
  }, 1200)
}

export const initializeExpenseButton = () => {
  const newExpenseButton = document.getElementById('new-expense-button')

  if (newExpenseButton) {
    newExpenseButton.addEventListener('click', addNewExpenseRow)
  }
}

export const destroyExpenseButton = () => {
  const newExpenseButton = document.getElementById('new-expense-button')

  if (newExpenseButton) {
    newExpenseButton.addEventListener('click', addNewExpenseRow)
  }
}

export const expenseAttachmentChanged = (e) => {
  const fileInput = document.getElementById(e.target.id)
  const imageDiv = fileInput.parentNode.querySelector('.attached-file-output')
  const existingImgDiv = fileInput.parentNode.querySelector('.already-attached')
  const imageTag = fileInput.parentNode.querySelector('.receipt-image')

  if (fileInput && imageDiv && imageTag && e.target.files) {
    const files = Array.from(e.target.files)

    if (files.length >= 1) {
      const src = URL.createObjectURL(files[0])
      const filename = files[0].name.replaceAll(' ', '-')
      imageTag.src = src
      imageTag.alt = filename
      imageDiv.style.display = 'block'
      if (existingImgDiv) {
        existingImgDiv.style.display = 'none'
      }
    }
  }
}

export const toggleExpenseRow = (e) => {
  // TODO: Find a better way to target element dynamically
  //  without chaining parentNode.parentNode...
  const closestFields = e.target.parentNode.parentNode.parentNode.parentNode.querySelector('.nested-expense-fields-body')

  if (closestFields) {
    if (closestFields.style.display === 'block') {
      closestFields.style.display = 'none'
    } else {
      closestFields.style.display = 'block'
    }
  }
}
