const toggleOpenState = () => {
  const navTrigger = document.getElementById('profile-nav-trigger')
  navTrigger.parentElement.classList.toggle('opened')
}

export const profileNavInit = () => {
  const navTrigger = document.getElementById('profile-nav-trigger')

  if (navTrigger) {
    navTrigger.addEventListener('click', toggleOpenState)
  }
}

export const profileNavDestroy = () => {
  const navTrigger = document.getElementById('profile-nav-trigger')

  if (navTrigger) {
    navTrigger.removeEventListener('click', toggleOpenState)
  }
}
