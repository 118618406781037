const onChanged = (e) => {
  const achInputs = document.getElementById('vendor-account-inputs')

  if (e.target.value === 'ACH') {
    achInputs.classList.remove('hidden')
  } else {
    achInputs.classList.add('hidden')
  }
}

export const vendorPaymentOptionsInit = () => {
  const accountInput = document.getElementById('vendor-account-toggle')
  const achOption = document.getElementById('vendor_preferred_payment_method_ach')

  if (achOption && achOption.checked) {
    const achInputs = document.getElementById('vendor-account-inputs')
    achInputs.classList.remove('hidden')
  }

  if (accountInput) {
    const radioOptions = [...accountInput.getElementsByTagName('input')]

    radioOptions.forEach((radioOption) => {
      radioOption.addEventListener('change', onChanged)
    })
  }
}

export const vendorPaymentOptionsDestroy = () => {
  const accountInput = document.getElementById('vendor-account-toggle')

  if (accountInput) {
    const radioOptions = [...accountInput.getElementsByTagName('input')]

    radioOptions.forEach((radioOption) => {
      radioOption.removeEventListener('change', onChanged)
    })
  }
}
