const onChanged = (e) => {
  const checkInputs = document.getElementById('check-payment-method-option')

  if (e.target.value === 'ACH') {
    checkInputs.classList.add('hidden')
  } else {
    checkInputs.classList.remove('hidden')
  }
}

export const paymentMethodsInit = () => {
  const accountInput = document.getElementById('user-account-pm-toggle')

  if (accountInput) {
    const radioOptions = [...accountInput.getElementsByTagName('input')]

    radioOptions.forEach((radioOption) => {
      radioOption.addEventListener('change', onChanged)
    })
  }
}

export const paymentMethodsDestroy = () => {
  const accountInput = document.getElementById('user-account-pm-toggle')

  if (accountInput) {
    const radioOptions = [...accountInput.getElementsByTagName('input')]

    radioOptions.forEach((radioOption) => {
      radioOption.removeEventListener('change', onChanged)
    })
  }
}
