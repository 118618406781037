import flatpickr from 'flatpickr'

export const dateTimePickerInit = () => {
  const elements = document.getElementsByClassName('apply-date-time-picker')

  Array.from(elements).forEach((element) => {
    if (element) {
      flatpickr(element, {
        enableTime: false,
      })
    }
  })
}

export const dateTimePickerDestroy = () => {
  const elements = document.getElementsByClassName('apply-date-time-picker')

  Array.from(elements).forEach((element) => {
    if (element) {
      flatpickr(element).destroy()
    }
  })
}
