const closeAlert = (e) => {
  e.target.parentElement.remove()
}

export const alertsInit = () => {
  const closeIcons = document.querySelectorAll('i.alert-close')

  closeIcons.forEach((item) => {
    item.addEventListener('click', closeAlert)
  })
}

export const alertsDestroy = () => {
  const closeIcons = document.querySelectorAll('i.alert-close')

  closeIcons.forEach((item) => {
    item.removeEventListener('click', closeAlert)
  })
}
