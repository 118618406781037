/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import MicroModal from 'micromodal'
import { selectedInvoices, selectedExpenses } from './selectedItems'
import { adjustPaymentRollupTotals } from './adjustTotals'
import { markAsRemoved } from './markAsRemoved'

const digestResponse = (response) => {
  if (!response.data || !response.data.payments) {
    return
  }

  const { payments } = response.data

  markAsRemoved(payments)
  adjustPaymentRollupTotals(payments)
}

export const removeSelectionsFromQueue = () => {
  const tokenElement = document.querySelector('meta[name=csrf-token]')
  const csrfToken = tokenElement ? tokenElement.content : ''

  axios.post('/payments/remove_from_queue.json', {
    invoice_ids: JSON.stringify(selectedInvoices()),
    expense_report_ids: JSON.stringify(selectedExpenses()),
  }, {
    headers: {
      'X-CSRF-Token': csrfToken,
    },
  })
    .then((response) => {
      digestResponse(response)
      MicroModal.close('site-modal')
    })
    .catch(() => {
      const siteModalContent = document.getElementById('site-modal-content')
      if (siteModalContent) {
        siteModalContent.innerHTML = 'There were issues removing the payment.'
        MicroModal.show('site-modal')
        document.getElementById('site-modal-confirm').classList.add('hidden')
      }
    })
}
