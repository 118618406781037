const handleResize = () => {
  const formActions = document.getElementById('form-actions')

  if (formActions) {
    formActions.style.width = `${formActions.parentElement.parentElement.offsetWidth}px`
  }
}

export const formActionsInit = () => {
  setTimeout(handleResize, 500)
  window.addEventListener('resize', handleResize)
}

export const formActionsDestroy = () => {
  clearTimeout(handleResize)
  window.removeEventListener('resize', handleResize)
}
