const toggleEmail = (event) => {
  const defaultCheckboxes = Array.from(document.getElementsByClassName('default-checkbox'))

  // Disabling the rule here as resolving the error using the suggested
  // `checkbox.setAttribute('checked', false)` breaks the functionality.
  defaultCheckboxes.forEach((checkbox) => {
    checkbox.checked = false // eslint-disable-line no-param-reassign
  })

  event.target.checked = true // eslint-disable-line no-param-reassign
}

export const toggleDefaultEmail = () => {
  const defaultCheckboxes = Array.from(document.getElementsByClassName('default-checkbox'))
  defaultCheckboxes.forEach((checkbox) => {
    checkbox.addEventListener('change', toggleEmail)
  })
}

export const toggleDefaultEmailDestroy = () => {
  const defaultCheckboxes = Array.from(document.getElementsByClassName('default-checkbox'))
  defaultCheckboxes.forEach((checkbox) => {
    checkbox.removeEventListener('change', toggleEmail)
  })
}
