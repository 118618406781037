const onCpcChanged = (e) => {
  const cpcWrapper = e.target.parentNode.parentNode.parentNode.querySelector('.cpc-option-wrapper')

  if (e.target.checked) {
    cpcWrapper.classList.remove('hidden')
  } else {
    cpcWrapper.classList.add('hidden')
  }
}

export const cpcToggleInit = () => {
  const cpcToggles = document.getElementsByClassName('cpc-toggle-trigger')

  if (cpcToggles) {
    Array.from(cpcToggles).forEach((toggle) => {
      toggle.addEventListener('change', onCpcChanged)

      const cpcWrapper = toggle.parentNode.parentNode.parentNode.querySelector('.cpc-option-wrapper')
      if (toggle.querySelector('input').checked && cpcWrapper) {
        cpcWrapper.classList.remove('hidden')
      } else if (cpcWrapper) {
        cpcWrapper.classList.add('hidden')
      }
    })
  }
}

export const cpcToggleDestroy = () => {
  const cpcToggles = document.getElementsByClassName('cpc-toggle-trigger')

  if (cpcToggles) {
    Array.from(cpcToggles).forEach((toggle) => {
      toggle.removeEventListener('change', onCpcChanged)
    })
  }
}
