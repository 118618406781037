const toggleOpenState = () => {
  const filterButton = document.getElementById('filter-button')
  filterButton.parentElement.classList.toggle('opened')
}

const preventClick = (event) => {
  event.stopPropagation()
}

export const filterNavInit = () => {
  const filterButton = document.getElementById('filter-button')

  if (filterButton) {
    filterButton.addEventListener('click', toggleOpenState)
  }

  const filterContent = document.getElementById('filter-content')

  if (filterContent) {
    filterContent.addEventListener('click', preventClick)
  }
}

export const filterNavDestroy = () => {
  const filterButton = document.getElementById('filter-button')

  if (filterButton) {
    filterButton.removeEventListener('click', toggleOpenState)
  }

  const filterContent = document.getElementById('filter-content')

  if (filterContent) {
    filterContent.removeEventListener('click', preventClick)
  }
}
