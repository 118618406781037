/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
export const markAsPaid = (payments) => {
  payments.forEach((payment) => {
    if (!payment || !payment.id) {
      return
    }

    const paymentId = payment.invoice_id ? `invoice-${payment.invoice_id}` : `expense-${payment.expense_report_id}`
    const paymentOption = document.getElementById(paymentId)
    const paymentOptionParent = paymentOption.parentNode
    const paidWrapper = document.createElement('div')
    paidWrapper.classList.add(`${paymentId}-payment`, 'paid-tag-wrapper')

    const paidTag = document.createElement('div')
    paidTag.classList.add('tag', 'tag-approved')
    paidTag.textContent = 'Paid'
    paidWrapper.appendChild(paidTag)

    if (payment.check_number && payment.total_amount_paid > 0) {
      const paidText = document.createElement('span')
      paidText.setAttribute('class', 'px-2')
      paidText.textContent = `#${payment.check_number}`

      const checkLink = document.createElement('span')
      const aTag = document.createElement('a')
      aTag.setAttribute('href', `/print_checks?check_number=${payment.check_number}&payment_method_id=${payment.payment_method_id}`)
      aTag.setAttribute('target', '_blank')
      aTag.setAttribute('class', 'inline check-print-quicklink')
      aTag.innerHTML = '<i class="fa fa-print" aria-hidden="true"></i>'
      checkLink.appendChild(aTag)

      paidWrapper.appendChild(paidText)
      paidWrapper.appendChild(checkLink)
    }

    paymentOptionParent.removeChild(paymentOption)
    paymentOptionParent.appendChild(paidWrapper)

    const paymentFormId = `edit_${payment.invoice_id ? `invoice_${payment.invoice_id}` : `expense_report_${payment.expense_report_id}`}`
    const paymentForm = document.getElementById(paymentFormId)
    Array.from(paymentForm.elements).forEach((formElement) => { formElement.disabled = true })
  })
}
