const toggleAccNumShow = () => {
  const accNum = document.getElementById('acc-num')
  const accNumShow = document.getElementById('acc-num-show')
  const accNumHide = document.getElementById('acc-num-hide')

  accNum.classList.remove('hidden')
  accNumHide.classList.remove('hidden')
  accNumShow.classList.add('hidden')
}

const toggleAccNumHide = () => {
  const accNum = document.getElementById('acc-num')
  const accNumShow = document.getElementById('acc-num-show')
  const accNumHide = document.getElementById('acc-num-hide')

  accNum.classList.add('hidden')
  accNumHide.classList.add('hidden')
  accNumShow.classList.remove('hidden')
}

export const toggleAccNumInit = () => {
  const accNumShow = document.getElementById('acc-num-show')
  const accNumHide = document.getElementById('acc-num-hide')

  if (accNumShow) {
    accNumShow.addEventListener('click', toggleAccNumShow)
  }

  if (accNumHide) {
    accNumHide.addEventListener('click', toggleAccNumHide)
  }
}

export const toggleAccNumDestroy = () => {
  const accNumShow = document.getElementById('acc-num-show')
  const accNumHide = document.getElementById('acc-num-hide')

  if (accNumShow) {
    accNumShow.addEventListener('click', toggleAccNumShow)
  }

  if (accNumHide) {
    accNumHide.addEventListener('click', toggleAccNumHide)
  }
}
