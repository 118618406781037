import MicroModal from 'micromodal'

const rejectUser = (userNotes) => {
  const notes = document.getElementById('user_notes')
  if (notes.value.normalize() === userNotes.normalize()) {
    const siteModalContent = document.getElementById('site-modal-content')
    const siteModalConfirmation = document.getElementById('site-modal-confirm')
    if (siteModalContent && siteModalConfirmation) {
      siteModalContent.innerHTML = 'Please update the \'Notes\' section with reason for rejection before rejecting the user.'
      MicroModal.show('site-modal')
      document.getElementById('site-modal-confirm').classList.add('hidden')
    }
    return false
  }

  return true
}

export default rejectUser
