const toggleFilters = () => {
  const filterOptions = document.getElementById('table-filter-options')
  if (filterOptions) {
    if (filterOptions.style.display === 'none') {
      filterOptions.style.display = 'block'
    } else {
      filterOptions.style.display = 'none'
    }
  }
}

export default toggleFilters
